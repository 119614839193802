import "../App.css";
import io from "socket.io-client";
import moment from "moment-timezone";
import Blocked from "../components/Blocked";
import alkhamisLogo from "../assets/logo.png";
import indianFlag from "../assets/indianFlag.png";
import londonFlag from "../assets/londonFlag.png";
import newYorkFlag from "../assets/newYorkFlag.png";
import MarketClose from "../components/MarketClose";
import bullionStats from "../assets/bullioStats.png";
import React, { useEffect, useMemo, useState } from "react";
import TradingViewChart from "../components/TradingViewChart";
import { commodityCalculation } from "../components/constants";
import EconomicNewsModal from "../components/EconomicNewsModal";
import SubscriptionExpired from "../components/SubscriptionExpired";
import { Box, LinearProgress, Modal, Typography } from "@mui/material";
import SubscriptionExpiringSoon from "../components/SubscriptionExpiringSoon";
import {
  allCommodities,
  allNewses,
  getAdminProfile,
  getConversionValue,
  getEconomicNews,
  getGoldPriceNews,
  getLiveValueTypeForDisplay,
  getRatio,
  getSocketUrl,
  getSpread,
} from "../sevice/home";

const { makeStyles } = require("@mui/styles");

let socket = localStorage.getItem("socketUrl")
  ? io(JSON.parse(localStorage.getItem("socketUrl")))
  : null;

const socket2 = io(process.env.REACT_APP_BACKEND_URL, { path: "/latest" });
const adminId = process.env.REACT_APP_ADMIN_ID;

const useStyles = makeStyles((theme) => ({
  mainBody: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  mainContainer: {
    width: "100%",
    height: "90vh",
    margin: "auto",
    display: "grid",
    gridTemplateColumns: "2fr 1.3fr",
    gridTemplateRows: "1fr",
    gridTemplateAreas: `
        'leftPart rightPart'
      `,
    padding: "2rem 2rem .8rem 2rem ",
    boxSizing: "border-box",
    columnGap: "2rem",
  },
  leftPart: {
    boxSizing: "border-box",
    gridArea: "leftPart",
    // gap: "1.2rem",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gridTemplateColumns: "1fr",
  },
  logo: {
    gridArea: "logo",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "10%",
  },
  leenLogoImg: {
    width: "55%",
    height: "auto",
  },
  ///////////////////////////////////
  time: {
    gridArea: "time",
    height: "10%",
    backgroundColor: "#A37C2D",
    borderRadius: "10px",
    display: "grid",
    paddingTop: "10px",
    paddingBottom: "10px",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridTemplateAreas: `
        'timeSub1 timeSub2 timeSub3 timeSub4'
      `,
    color: "#FFFDD2",
  },
  timeSub1: {
    gridArea: "timeSub1",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  flagImage: {
    height: "3vw",
    marginRight: "5px",
  },
  timeSub2: {
    gridArea: "timeSub2",
    display: "flex",
    marginTop: "5px",
  },
  timeSub3: {
    gridArea: "timeSub3",
    display: "flex",
    marginTop: "5px",
  },
  timeSub4: {
    gridArea: "timeSub4",
    display: "flex",
    marginTop: "5px",
  },
  //////////////////
  commoditieTable: {
    gridArea: "commoditieTable",
    height: "55%",
    display: "flex",
    flexDirection: "column",
  },
  table: {
    gridArea: "table",
    gap: "10px",
    // justifyContent: "space-between",
    boxSizing: "border-box",
    color: "#FFFDD2",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  tabeHeader: {
    gridArea: "tabeHeader",
    backgroundColor: "#A37C2D",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    borderRadius: "5px",
    alignItems: "center",
    height: "20%",
  },
  tableContent: {
    gridArea: "tableContent",
    display: "flex",
    height: "80%",
    flexDirection: "column",
    // gap: ".3em",
    gap: "6px",
    justifyContent: "space-between",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tableRowColumn: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    color: "#FFFDD2",
    fontSize: "2vw",
  },
  tableRow1: {
    flexBasis: 0,
    minHeight: "15%",
    flexGrow: 1,
    gridArea: "tableRow1",
    borderRadius: "5px",
    background: "#A37C2D",
    display: "flex",
  },
  //////////////////////////
  currency: {
    gridArea: "currency",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    height: "15%",
    color: "#FFFDD2",
  },
  currencyCol: {
    width: "25%",
    backgroundColor: "#A37C2D",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  //////////////////////////////////
  rightPart: {
    gridArea: "rightPart",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    // gap: "1rem",
    justifyContent: "space-between",
  },
  spotRate: {
    height: "35%",
    gridArea: "spotRate",
    backgroundColor: "#A37C2D",
    display: "flex",
    flexDirection: "column",
    gap: ".6rem",
    borderRadius: "5px",
    padding: "10px 10px 20px 10px",
    color: "#FFFDD2",
  },
  spotRateBoxGoldRow2Col1: {
    gridArea: "spotRateBoxGoldRow2Col1",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxGoldRow2Col2: {
    gridArea: "spotRateBoxGoldRow2Col2",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxSilverRow2Col1: {
    gridArea: "spotRateBoxSilverRow2Col1",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxSilverRow2Col2: {
    gridArea: "spotRateBoxSilverRow2Col2",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },

  chart: {
    height: "42%",
  },

  stat: {
    height: "15%",
    gridArea: "stat",
    backgroundColor: "#A37C2D",
    color: "#FFFDD2",
    borderRadius: "5px",
  },
  bullionStatsImg: {
    // width: "150px",
    width: "9vw",
    height: "auto",
  },
  ////////////////////////////////////////
  updates: {
    padding: "0rem 2rem 0rem 2rem",
    display: "flex",
    height: "9vh",
    color: "#FFFDD2",
  },
  updatesHeader: {
    background: "#A37C2D",
    width: "290px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px 0px 0px 5px",
  },
  updatesContent: {
    display: "flex",
    alignItems: "center",
    background: "#B4964E",
    width: "100%",
    borderRadius: "0px 5px 5px 0px",
  },
  blur: {
    filter: "blur(8px)",
  },
}));

const Luxurybullion = () => {
  const classes = useStyles();
  const [adminData, setAdminData] = useState();
  const [openSubscriptionExpireSoon, setOpenSubscriptionExpireSoon] =
    useState(false);
  const [openSubscriptionExpierd, setOpenSubscriptionExpierd] = useState(false);
  const [openBlocked, setOpenBlocked] = useState(false);
  const [isMarketOpen, setIsMarketOpen] = useState(true);
  const [render, setRender] = useState(true);

  const [gold, setGold] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [silver, setSilver] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [ratio, setRatio] = useState({
    Buyers: "0%",
    chgValue: "0.00%",
    commodity: "Gold",
  });
  const [displayBidOrBuy, setDisplayBidOrBuy] = useState({
    bidOrBuy: "Bid",
    askOrSell: "Ask",
  });
  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });

  const [commodities, setCommodities] = useState([]);
  const [usdConversion, setUsdConversion] = useState({});
  const [thbConversion, setThbConversion] = useState({});
  const [hkdConversion, setHkdConversion] = useState({});
  const [news, setNews] = useState([]);
  const [goldNews, setGoldNews] = useState({});

  const [timeZones, setTimeZones] = useState({
    indianTime: moment(Date.now()).tz("Asia/Kolkata").format("hh:mm A"),
    newYorkTime: moment(Date.now()).tz("America/New_York").format("hh:mm A"),
    londonTime: moment(Date.now()).tz("Europe/London").format("hh:mm A"),
  });

  const updateTime = () => {
    setTimeZones({
      ...timeZones,
      indianTime: moment(Date.now()).tz("Asia/Kolkata").format("hh:mm A"),
      newYorkTime: moment(Date.now()).tz("America/New_York").format("hh:mm A"),
      londonTime: moment(Date.now()).tz("Europe/London").format("hh:mm A"),
    });
  };

  setInterval(updateTime, 1000);

  // Block
  const handleOpenBlocked = () => setOpenBlocked(true);
  const handleCloseBlocked = () => {
    window.location.reload();
  };
  // SubscriptionExpierd
  const handleOpenSubscriptionExpierd = () => setOpenSubscriptionExpierd(true);
  const handleCloseSubscriptionExpierd = () => window.location.reload();
  // SubscriptionExpierSoon
  const handleExpireSoonOpen = () => setOpenSubscriptionExpireSoon(true);
  const handleOpenExpireSoonClose = () => setOpenSubscriptionExpireSoon(false);

  // Check is Market Closed
  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment()?.tz(adminData?.time_zone);
      const dayOfWeek = now?.day();
      const hour = now?.hour();
      if (
        (dayOfWeek >= 1 && dayOfWeek <= 5 && hour >= 1 && hour < 2) || // Monday to Friday, 1AM to 2AM
        (dayOfWeek === 6 && hour >= 1) || // Saturday, 1AM onwards
        dayOfWeek === 0 || // Sunday
        (dayOfWeek === 1 && hour < 2) // Monday, before 2AM
      ) {
        setIsMarketOpen(false);
      } else {
        setIsMarketOpen(true);
      }
    }, 1000); // Check every minute

    return () => clearInterval(interval);
  }, [adminData?.time_zone]);

  useMemo(() => {
    if (socket && render) {
      setRender(false);
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
      socket.on("gold-rate-change", (data) => {
        // setIsMarketOpen(data.data.isMarketOpen);
        setGold((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
      socket.on("silver-rate-change", (data) => {
        setSilver((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    socket2.on("connect", () => {});
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });
    socket2.on("commodityRerender", (data) => {
      handleFindCommodities();
    });
    socket2.on("newsRerender", (data) => {
      hadlefetchNews();
    });
    socket2.on("spreadRerender", (data) => {
      handleFindSpread();
    });
    socket2.on("adminBlockRerender", (data) => {
      window.location.reload();
      // handleFindAdminProfile();
    });
    socket2.on("socketRerender", (data) => {
      localStorage.removeItem("socketUrl");
      window.location.reload();
    });
    socket2.on("reloadRender", (data) => {
      window.location.reload();
    });
    socket2.on("upgradeRerender", (data) => {
      handleFindAdminProfile();
    });
    socket2.on("bidAndAskTitleRerender", (data) => {
      handleFindLiveValueTypeForDisplay();
    });
    socket2.on("economicNewsRender", (data) => {
      handleFindEconomicNews();
    });
  }, []);

  const handleFindCommodities = async () => {
    const res = await allCommodities();
    if (Array.isArray(res.commodities)) {
      setCommodities(res.commodities);
    }
  };
  const handleFindSpread = async () => {
    const res = await getSpread();
    if (res) {
      setSpread(res);
    }
  };
  const hadlefetchNews = async () => {
    const res = await allNewses();
    if (Array.isArray(res)) {
      setNews(res);
    }
  };
  const handleFindGoldPriceNews = async () => {
    const res = await getGoldPriceNews();
    if (res.length > 0) setGoldNews(res[0]);
  };

  const handleFindLiveValueTypeForDisplay = async () => {
    const res = await getLiveValueTypeForDisplay();
    if (res) {
      setDisplayBidOrBuy(res);
    }
  };
  const handleFindRatio = async () => {
    const res = await getRatio();
    if (res?.data) {
      setRatio(res?.data);
    }
  };
  const handleFindSocketUrl = async () => {
    const res = await getSocketUrl();
    if (res) {
      socket = io(res?.socketURL);
      localStorage.setItem("socketUrl", JSON.stringify(res?.socketURL));
    }
  };

  const handleFindAdminProfile = async () => {
    const res = await getAdminProfile();
    setAdminData(res);
    if (!res.status || res.isBlocked) {
      handleOpenBlocked();
    } else if (!res.isBlocked && openBlocked) {
    } else {
      handlecheckSubscriptionExpierd(res);
    }
  };
  const handleFindConversionValue = async () => {
    const usd = await getConversionValue("USD");
    setUsdConversion(usd?.conversion_rates);
    const thb = await getConversionValue("THB");
    setThbConversion(thb?.conversion_rates);
    const hkd = await getConversionValue("HKD");
    setHkdConversion(hkd?.conversion_rates);
  };

  const handlecheckSubscriptionExpierd = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    let a = givenDate.toISOString();
    let b = new Date().toISOString();
    if (a < b) {
      handleOpenSubscriptionExpierd();
    }
  };

  useEffect(() => {
    if (adminData) {
      const interval = setInterval(() => {
        checkSubscriptionExpireSoon(adminData);
      }, 60 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [adminData]);

  const checkSubscriptionExpireSoon = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();
    if (b >= a) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour === 10) {
        handleExpireSoonOpen();
      }
    }
  };

  useEffect(() => {
    handleFindCommodities();
    handleFindSpread();
    hadlefetchNews();
    handleFindLiveValueTypeForDisplay();
    handleFindRatio();
    handleFindSocketUrl();
    handleFindAdminProfile();
    handleFindEconomicNews();
    handleFindGoldPriceNews();
    handleFindConversionValue();
  }, []);

  const [economicNews, setEconomicNews] = useState([]);
  const [openNews, setOpenNews] = useState(false);
  const handleOpenopenNews = () => setOpenNews(true);
  const handleCloseopenNews = () => setOpenNews(false);

  const [curEconomicNews, setCurEconomicNews] = useState([]);
  const handleFindEconomicNews = async () => {
    const res = await getEconomicNews();
    setEconomicNews(res);
  };

  useEffect(() => {
    const timeInterwell = setInterval(() => {
      economicNewsFinding();
    }, 1000 * 60 * 1);
    economicNewsFinding();
    return () => clearInterval(timeInterwell);
  }, [economicNews]);

  const economicNewsFinding = () => {
    if (economicNews.length) {
      const currentTime = new Date(new Date().toUTCString());
      const oneHourAgo = new Date(currentTime);
      oneHourAgo.setHours(currentTime.getHours() + 1);
      oneHourAgo.setSeconds(0);
      oneHourAgo.setMilliseconds(0);

      const currentTimeMin = new Date(new Date().toUTCString());
      const fiveMinAgo = new Date(currentTimeMin);
      fiveMinAgo.setMinutes(currentTimeMin.getMinutes());
      fiveMinAgo.setSeconds(0);
      fiveMinAgo.setMilliseconds(0);

      let objectBeforeFiveMin = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - fiveMinAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= 0;
      });
      let objectBeforeOneHour = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - oneHourAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= -5;
      });
      if (objectBeforeFiveMin.length) {
        objectBeforeFiveMin = objectBeforeFiveMin?.sort(
          (a, b) => b.priority - a.priority
        );
        setCurEconomicNews(objectBeforeFiveMin);
        handleOpenopenNews();
      } else if (objectBeforeOneHour.length) {
        objectBeforeOneHour = objectBeforeOneHour?.sort(
          (a, b) => b.priority - a.priority
        );
        setCurEconomicNews(objectBeforeOneHour);
        handleOpenopenNews();
      } else {
        setCurEconomicNews([]);
        handleCloseopenNews();
      }
    } else {
      setCurEconomicNews([]);
    }
  };

  const progress = Number(ratio?.Buyers?.match(/\d+/)[0]);
  const buyersColor = "blue";
  const sellersColor = "#EE3E3E";
  const progressStyle = { backgroundColor: sellersColor };
  const barStyle = { backgroundColor: buyersColor };

  const formatDate = () => {
    return moment(new Date()).format("DD MMM YYYY");
  };

  const formatTime = () => {
    return new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <div>
      <Box
        className={
          (classes.mainBody,
          (openSubscriptionExpierd || openBlocked) && classes.blur)
        }
      >
        <Box className={classes.mainContainer}>
          {!isMarketOpen && <MarketClose timeZone={adminData?.time_zone} />}
          <Box className={classes.leftPart}>
            <Box className={classes.logo}>
              <img
                className={classes.leenLogoImg}
                src={alkhamisLogo}
                alt="alkhamisLogo"
              />
            </Box>
            <Box className={classes.time}>
              <Box className={classes.timeSub1}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "2vw",
                  }}
                >
                  {formatTime()}
                </Typography>
                <Typography sx={{ fontWeight: "bold", fontSize: "1vw" }}>
                  {formatDate()}
                </Typography>
              </Box>
              <Box className={classes.timeSub2}>
                <Box>
                  <img
                    src={londonFlag}
                    alt="indian Flag"
                    className={classes.flagImage}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: "1.1vw" }}>
                    LONDON
                  </Typography>
                  <Typography sx={{ fontWeight: "bold", fontSize: "1.5vw" }}>
                    {timeZones.londonTime}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.timeSub3}>
                <Box>
                  <img
                    src={indianFlag}
                    alt="indian Flag"
                    className={classes.flagImage}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.1vw",
                      color: "FFFDD2",
                    }}
                  >
                    INDIA
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.5vw",
                      color: "FFFDD2",
                    }}
                  >
                    {timeZones.indianTime}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.timeSub4}>
                <Box>
                  <img
                    src={newYorkFlag}
                    alt="indian Flag"
                    className={classes.flagImage}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: "1.1vw" }}>
                    NEW YORK
                  </Typography>
                  <Typography sx={{ fontWeight: "bold", fontSize: "1.5vw" }}>
                    {timeZones.newYorkTime}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className={classes.commoditieTable}>
              {/* //////////////////////// */}
              <Box className={classes.table}>
                <Box className={classes.tabeHeader}>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    COMMODITY
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    WEIGHT
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    BUY
                    <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                      &nbsp;AED
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    SELL
                    <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                      &nbsp;AED
                    </Typography>
                  </Typography>
                </Box>

                <Box className={classes.tableContent}>
                  {commodities?.map((commodity, idx) => {
                    const words = commodity.commodity_title.split(" ");
                    return (
                      <Box key={idx} className={classes.tableRow1}>
                        <Typography
                          sx={{
                            fontSize: "1.3vw",
                            fontWeight: "bold",
                            justifyContent: "left",
                            // pl: "2rem",
                          }}
                          className={classes.tableRowColumn}
                        >
                          {words.map((word, index) => {
                            if (index === 0) {
                              return (
                                <span
                                  key={index}
                                  style={{
                                    fontSize: "2vw",
                                    fontWeight: "bold",
                                    paddingLeft: "30px",
                                  }}
                                >
                                  {word.toUpperCase()}
                                </span>
                              );
                            } else {
                              return (
                                <span
                                  key={index}
                                  style={{
                                    fontSize: "1vw",
                                    marginTop: "0.2rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  &nbsp;{word.toUpperCase()}
                                </span>
                              );
                            }
                          })}
                          &nbsp;
                          {commodity.unitLabel === "TTB"
                            ? ""
                            : commodity.displayPurity}
                        </Typography>{" "}
                        <Box
                          className={classes.tableRowColumn}
                          sx={{
                            justifyContent: "left",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "2.2vw", fontWeight: "bold" }}
                            sx={{
                              paddingLeft: { lg: "3.9vw", md: "3.5vw" },
                            }}
                          >
                            {commodity.unit} {commodity.unitLabel}
                          </Typography>
                        </Box>
                        <Box
                          className={classes.tableRowColumn}
                          sx={{
                            justifyContent: "left",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "2vw", fontWeight: "bold" }}
                            sx={{
                              paddingLeft: { lg: "3.5vw", md: "3.1vw" },
                            }}
                          >
                            {gold?.cur?.bid
                              ? commodityCalculation(
                                  commodity.commodity_title === "Silver"
                                    ? silver?.cur?.bid
                                    : gold?.cur?.bid,
                                  commodity.commodity_title === "Silver"
                                    ? spread.silverBidSpread
                                    : spread.goldBidSpread,
                                  commodity.buy_premium,
                                  3.674,
                                  commodity.purity,
                                  commodity.unit,
                                  commodity.unitLabel === "TTB"
                                    ? 116.64
                                    : commodity.unitLabel === "KG"
                                    ? 1000
                                    : commodity.unitLabel === "OZ"
                                    ? 31.1
                                    : commodity.unitLabel === "TOLA"
                                    ? 11.7
                                    : 1,
                                  commodity.buy_charge
                                )
                              : "0.00"}
                          </Typography>
                        </Box>
                        <Box className={classes.tableRowColumn}>
                          <Typography
                            style={{ fontSize: "2vw", fontWeight: "bold" }}
                            sx={{
                              paddingLeft: { lg: "3.5vw", md: "3.1vw" },
                            }}
                          >
                            {gold?.cur?.bid
                              ? commodityCalculation(
                                  commodity.commodity_title === "Silver"
                                    ? silver?.cur?.ask
                                    : gold?.cur?.ask,
                                  commodity.commodity_title === "Silver"
                                    ? spread.silverAskSpread
                                    : spread.goldAskSpread,
                                  commodity.premium,
                                  3.674,
                                  commodity.purity,
                                  commodity.unit,
                                  commodity.unitLabel === "TTB"
                                    ? 116.64
                                    : commodity.unitLabel === "KG"
                                    ? 1000
                                    : commodity.unitLabel === "OZ"
                                    ? 31.1
                                    : commodity.unitLabel === "TOLA"
                                    ? 11.7
                                    : 1,
                                  commodity.charges
                                )
                              : "0.00"}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
            <Box className={classes.currency}>
              <Box className={classes.currencyCol}>
                <Typography sx={{ fontSize: "1.5vw", color: "#B22234" }}>
                  HKD <span style={{ color: "#124D28" }}>/</span>
                  <span style={{ color: "#01008A" }}> INR</span>
                </Typography>
                <Typography sx={{ fontWeight: "bold", fontSize: "1.5vw" }}>
                  {hkdConversion?.INR?.toFixed(2) || "0.00"}
                </Typography>
              </Box>
              <Box className={classes.currencyCol}>
                <Typography sx={{ fontSize: "1.5vw", color: "#B22234" }}>
                  USD <span style={{ color: "#124D28" }}>/</span>
                  <span style={{ color: "#01008A" }}> INR</span>
                </Typography>
                <Typography sx={{ fontWeight: "bold", fontSize: "1.5vw" }}>
                  {usdConversion?.INR?.toFixed(2) || "0.00"}
                </Typography>
              </Box>
              <Box className={classes.currencyCol}>
                <Typography sx={{ fontSize: "1.5vw", color: "#B22234" }}>
                  THB <span style={{ color: "#124D28" }}>/</span>
                  <span style={{ color: "#01008A" }}> INR</span>
                </Typography>
                <Typography sx={{ fontWeight: "bold", fontSize: "1.5vw" }}>
                  {thbConversion?.INR?.toFixed(2) || "0.00"}
                </Typography>
              </Box>
            </Box>
            {/* /////////////////////// */}
          </Box>
          <Box className={classes.rightPart}>
            <Box className={classes.spotRate}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  py: "10px",
                  height: "20%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.4vw",
                    fontWeight: "bold",
                    flexBasis: 0,
                    flexGrow: 1,
                    flexShrink: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  SPOT RATE
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    flexBasis: 0,
                    flexGrow: 1,
                    flexShrink: 1,
                  }}
                >
                  <Box
                    sx={{
                      color: "#A37C2D",
                      background: "#FFFDD2",
                      px: "7px",
                      display: "inline-block",
                      width: "fit-content",
                      borderRadius: "4px",
                    }}
                  >
                    <Typography style={{ fontSize: "1vw", fontWeight: "bold" }}>
                      $
                    </Typography>
                  </Box>
                  <Typography sx={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                    {displayBidOrBuy?.bidOrBuy?.toUpperCase()}
                  </Typography>
                  <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    // width: "35%",
                    // width: "100px",
                    // width: "7.5vw",
                    // px: 1.5,
                    flexBasis: 0,
                    flexGrow: 1,
                    flexShrink: 1,
                  }}
                >
                  <Box
                    sx={{
                      color: "#A37C2D",
                      background: "#FFFDD2",
                      px: "7px",
                      display: "inline-block",
                      width: "fit-content",
                      borderRadius: "4px",
                    }}
                  >
                    <Typography style={{ fontSize: "1vw", fontWeight: "bold" }}>
                      $
                    </Typography>
                  </Box>
                  <Typography sx={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                    {displayBidOrBuy?.askOrSell?.toUpperCase()}
                  </Typography>
                  <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  height: "42%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "2vw",
                    fontWeight: "bold",
                    marginBottom: 4,
                    flexBasis: 0,
                    flexGrow: 1,
                    flexShrink: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  GOLD
                </Typography>

                <Box className={classes.spotRateBoxGoldRow2Col1}>
                  <Box
                    sx={{
                      background:
                        gold?.cur?.bid < gold?.pre?.bid
                          ? "#DE5B56"
                          : gold?.cur?.bid > gold?.pre?.bid
                          ? "#5FA147"
                          : "#2F2E2C",
                      border: "1px solid #FFFDD2",
                      color: "#FFFDD2",
                      borderRadius: "5px",
                      width: "7.3vw",
                      height: "6.3vh",
                      display: "flex",
                      alignItems: "center",
                      px: 1.5,
                    }}
                  >
                    <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                      {gold?.cur?.bid
                        ? (
                            Number(spread.goldBidSpread) +
                            Number(gold?.cur?.bid)
                          ).toFixed(2)
                        : "0.00"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      // pl: 1,
                    }}
                  >
                    <Box
                      sx={{
                        background: "#DE5B56",
                        color: "#FFFDD2",
                        px: 1,
                        display: "inline-block",
                        width: "fit-content",
                      }}
                    >
                      <Typography style={{ fontSize: "1vw" }}>Low</Typography>
                    </Box>
                    <Typography style={{ fontSize: "1.3vw" }}>
                      {gold?.cur?.lowPrice
                        ? (
                            Number(spread.goldLowSpread) +
                            Number(gold?.cur?.lowPrice)
                          ).toFixed(2)
                        : "0.00"}
                    </Typography>
                  </Box>
                </Box>

                <Box className={classes.spotRateBoxGoldRow2Col2}>
                  <Box
                    sx={{
                      background:
                        gold?.cur?.ask < gold?.pre?.bid
                          ? "#DE5B56"
                          : gold?.cur?.ask > gold?.pre?.ask
                          ? "#5FA147"
                          : "#2F2E2C",
                      border: "1px solid #FFFDD2",
                      color: "#FFFDD2",
                      borderRadius: "5px",
                      width: "7.3vw",
                      height: "6.3vh",
                      display: "flex",
                      alignItems: "center",
                      px: 1.5,
                    }}
                  >
                    <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                      {gold?.cur?.ask
                        ? (
                            Number(spread.goldAskSpread) +
                            Number(gold?.cur?.ask)
                          ).toFixed(2)
                        : "0.00"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      // pl: 1,
                    }}
                  >
                    <Box
                      sx={{
                        background: "#5FA147",
                        color: "#FFFDD2",
                        px: 1,
                        display: "inline-block",
                        width: "fit-content",
                      }}
                    >
                      <Typography style={{ fontSize: "1vw" }}>High</Typography>
                    </Box>
                    <Typography style={{ fontSize: "1.3vw" }}>
                      {gold?.cur?.highPrice
                        ? (
                            Number(spread.goldHighSpread) +
                            Number(gold?.cur?.highPrice)
                          ).toFixed(2)
                        : "0.00"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  height: "35%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.6vw",
                    marginBottom: 4,
                    flexBasis: 0,
                    flexGrow: 1,
                    flexShrink: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  SILVER
                </Typography>

                <Box className={classes.spotRateBoxSilverRow2Col1}>
                  <Box
                    sx={{
                      background:
                        silver?.cur?.bid < silver?.pre?.bid
                          ? "#DE5B56"
                          : silver?.cur?.bid > silver?.pre?.bid
                          ? "#5FA147"
                          : "#2F2E2C",
                      border: "1px solid #FFFDD2",
                      color: "#FFFDD2",
                      borderRadius: "5px",
                      px: 1.5,
                      width: "7.3vw",
                      height: "5vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "2vw" }}>
                      {silver?.cur?.bid
                        ? (
                            Number(spread.silverAskSpread) +
                            Number(silver?.cur?.bid)
                          ).toFixed(3)
                        : "0.000"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                  >
                    <Box
                      sx={{
                        background: "#DE5B56",
                        color: "#FFFDD2",
                        px: 1,
                        display: "inline-block",
                        width: "fit-content",
                      }}
                    >
                      <Typography style={{ fontSize: "1vw" }}>Low</Typography>
                    </Box>
                    <Typography style={{ fontSize: "1vw" }}>
                      {silver?.cur?.lowPrice
                        ? (
                            Number(spread.silverLowSpread) +
                            Number(silver?.cur?.lowPrice)
                          ).toFixed(3)
                        : "0.000"}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                  </Box>
                </Box>

                <Box className={classes.spotRateBoxSilverRow2Col2}>
                  <Box
                    sx={{
                      background:
                        silver?.cur?.ask < silver?.pre?.ask
                          ? "#DE5B56"
                          : silver?.cur?.ask > silver?.pre?.ask
                          ? "#5FA147"
                          : "#2F2E2C",
                      border: "1px solid #FFFDD2",
                      color: "#FFFDD2",
                      borderRadius: "5px",
                      px: 1.5,
                      width: "7.3vw",
                      height: "5vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "2vw" }}>
                      {silver?.cur?.ask
                        ? (
                            Number(spread.silverBidSpread) +
                            Number(silver?.cur?.ask)
                          ).toFixed(3)
                        : "0.000"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                  >
                    <Box
                      sx={{
                        background: "#5FA147",
                        color: "#FFFDD2",
                        px: 1,
                        display: "inline-block",
                        width: "fit-content",
                      }}
                    >
                      <Typography style={{ fontSize: "1vw" }}>High</Typography>
                    </Box>
                    <Typography style={{ fontSize: "1vw" }}>
                      {silver?.cur?.highPrice
                        ? (
                            Number(spread.silverHighSpread) +
                            Number(silver?.cur?.highPrice)
                          ).toFixed(3)
                        : "0.000"}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={classes.chart}>
              <TradingViewChart symbol={"XAUUSD"} />
            </Box>
            <Box className={classes.stat}>
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 5,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography sx={{ fontSize: "1.3vw" }}>BUYERS</Typography>
                  <Typography
                    sx={{
                      color:
                        Number(ratio?.chgValue.replace("%", "")) >= 0
                          ? "blue"
                          : "#EE3E3E",
                      fontSize: "1.3vw",
                    }}
                  >
                    {ratio?.chgValue}
                  </Typography>
                  <Typography sx={{ fontSize: "1.3vw" }}>SELLERS</Typography>
                </Box>
                <Box sx={{ width: "90%", px: 4 }}>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    style={progressStyle}
                    sx={{
                      "& .MuiLinearProgress-bar": barStyle,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 7,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography sx={{ fontSize: "1.3vw", color: "blue" }}>
                    {ratio?.Buyers}
                  </Typography>
                  <Box sx={{}}>
                    <a href="https://www.bullionstats.com/">
                      <img
                        className={classes.bullionStatsImg}
                        src={bullionStats}
                        alt="bullionStats"
                      />
                    </a>
                  </Box>
                  <Typography sx={{ fontSize: "1.3vw", color: "red" }}>
                    {Number(ratio?.Buyers?.match(/\d+/)[0])
                      ? 100 - Number(ratio?.Buyers?.match(/\d+/)[0])
                      : 0}
                    %
                  </Typography>
                </Box>
              </>
            </Box>
          </Box>
        </Box>
        <Box className={classes.updates}>
          <Box className={classes.updatesHeader}>
            <Typography
              sx={{
                fontSize: "1.3vw",
                fontWeight: "bold",
              }}
            >
              LUXURY BULLION
            </Typography>
          </Box>
          <Box className={classes.updatesContent} sx={{ height: "100%" }}>
            <marquee
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex" }}>
                {!adminData?.isNewsEnable &&
                Object.keys(goldNews).length > 0 ? (
                  <Box
                    style={{
                      fontSize: "1.5vw",
                      fontFamily: "poppins",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Gold Price News</span>
                    &nbsp;&nbsp;:&nbsp;&nbsp;<span>{goldNews?.title}</span>
                  </Box>
                ) : (
                  news?.map((item, index) => {
                    return (
                      <Box
                        key={item._id}
                        style={{
                          fontSize: "1.5vw",
                          fontFamily: "poppins",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {item.newsTitle}
                        </span>
                        &nbsp;&nbsp;:&nbsp;&nbsp;<span>{item.newsDetails}</span>
                        <span
                          style={{ paddingRight: "50px", paddingLeft: "50px" }}
                        >
                          {index === news.length - 1 ? "" : "|"}
                        </span>
                      </Box>
                    );
                  })
                )}
              </Box>
            </marquee>
          </Box>
        </Box>
      </Box>
      <Modal open={openBlocked} closeAfterTransition>
        <Blocked handleClose={handleCloseBlocked} />
      </Modal>
      <Modal open={openSubscriptionExpierd} closeAfterTransition>
        <SubscriptionExpired handleClose={handleCloseSubscriptionExpierd} />
      </Modal>
      <Modal
        open={openSubscriptionExpireSoon}
        onClose={handleOpenExpireSoonClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.1)", // Adjust the color and opacity as needed
            backdropFilter: "none", // This disables the blur effect
          },
        }}
      >
        <SubscriptionExpiringSoon
          date={adminData?.package.expire_date}
          handleClose={handleOpenExpireSoonClose}
        />
      </Modal>
      <Modal
        open={openNews}
        onClose={handleCloseopenNews}
        closeAfterTransition
        hideBackdrop
      >
        <EconomicNewsModal
          handleClose={handleCloseopenNews}
          curEconomicNews={curEconomicNews}
        />
      </Modal>
    </div>
  );
};

export default Luxurybullion;
